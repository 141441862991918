import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Api from '@/resources/NajaSocialApi'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/auth',
      name: 'Autenticando',
      meta: { public: true },
      component: () => import('@/views/auth'),
    },
    {
      path: '/hc',
      name: 'healthcheck',
      meta: { layout: 'basic', public: true },
      component: () => import('@/views/healthcheck'),
    },
    {
      path: '/',
      name: 'Home',
      meta: { public: true },
      component: () => import('@/views/home'),
    },
    {
      path: '/users',
      name: 'Users',
      meta: { title: 'Usuários', permission: 'naja_app_users' },
      component: () => import('@/views/users'),
    },
    {
      path: '/users/new',
      name: 'UserNew',
      meta: { title: 'Novo Usuário', permission: 'naja_app_users_new' },
      component: () => import('@/views/users/edit'),
    },
    {
      path: '/users/:id',
      name: 'UserShow',
      meta: { title: 'Visualizar Usuário', permission: 'naja_app_users' },
      component: () => import('@/views/users/show'),
    },
    {
      path: '/users/:id/edit',
      name: 'UserEdit',
      meta: { title: 'Editar Usuário', permission: 'naja_app_users_edit' },
      component: () => import('@/views/users/edit'),
    },
    {
      path: '/task_schedulers',
      name: 'TaskSchedulers',
      meta: {
        title: 'Agendamento de tarefas',
        permission: 'naja_app_task_schedulers',
      },
      component: () => import('@/views/task_schedulers'),
    },
    {
      path: '/task_schedulers/new',
      name: 'TaskSchedulerNew',
      meta: {
        title: 'Novo Agendamento de Tarefa',
        permission: 'naja_app_task_schedulers_new',
      },
      component: () => import('@/views/task_schedulers/edit'),
    },
    {
      path: '/task_schedulers/:id',
      name: 'TaskSchedulerShow',
      meta: {
        title: 'Visualizar Agendamento de Tarefa',
        permission: 'naja_app_task_schedulers',
      },
      component: () => import('@/views/task_schedulers/show'),
    },
    {
      path: '/task_schedulers/:id/edit',
      name: 'TaskSchedulerEdit',
      meta: {
        title: 'Editar Agendamento de Tarefa',
        permission: 'naja_app_task_schedulers_edit',
      },
      component: () => import('@/views/task_schedulers/edit'),
    },
    {
      path: '/outboxes',
      name: 'Outboxes',
      meta: {
        title: 'Caixas de Saída',
        permission: 'naja_app_outboxes',
      },
      component: () => import('@/views/outboxes'),
    },
    {
      path: '/outboxes/new',
      name: 'OutboxNew',
      meta: {
        title: 'Nova Caixa de Saída',
        permission: 'naja_app_outboxes_new',
      },
      component: () => import('@/views/outboxes/edit'),
    },
    {
      path: '/outboxes/:id',
      name: 'OutboxShow',
      meta: {
        title: 'Visualizar Caixa de Saída',
        permission: 'naja_app_outboxes',
      },
      component: () => import('@/views/outboxes/show'),
    },
    {
      path: '/outboxes/:id/edit',
      name: 'OutboxEdit',
      meta: {
        title: 'Editar Caixa de Saída',
        permission: 'naja_app_outboxes_edit',
      },
      component: () => import('@/views/outboxes/edit'),
    },
    {
      path: '/profiles',
      name: 'Profiles',
      meta: { title: 'Perfis de Acesso', permission: 'naja_app_profiles' },
      component: () => import('@/views/profiles'),
    },
    {
      path: '/profiles/new',
      name: 'ProfileNew',
      meta: {
        title: 'Novo Perfil de Acesso',
        permission: 'naja_app_profiles_new',
      },
      component: () => import('@/views/profiles/edit'),
    },
    {
      path: '/profiles/:id',
      name: 'ProfileShow',
      meta: {
        title: 'Visualizar Perfil de Acesso',
        permission: 'naja_app_profiles',
      },
      component: () => import('@/views/profiles/show'),
    },
    {
      path: '/profiles/:id/edit',
      name: 'ProfileEdit',
      meta: {
        title: 'Editar Perfil de Acesso',
        permission: 'naja_app_profiles_edit',
      },
      component: () => import('@/views/profiles/edit'),
    },
    {
      path: '/schedules',
      name: 'Schedules',
      meta: { title: 'Agendamentos', permission: 'naja_app_schedules' },
      component: () => import('@/views/schedules'),
    },
    {
      path: '/inbox_messages',
      name: 'InboxMessages',
      meta: {
        title: 'Mensagens de Entrada',
        permission: 'naja_app_inbox_messages',
      },
      component: () => import('@/views/inbox_messages'),
    },
    {
      path: '/notifications',
      name: 'Notifications',
      meta: {
        title: 'Log de Notificações',
        permission: 'naja_app_notifications',
      },
      component: () => import('@/views/notifications'),
    },
    {
      path: '/header_footer_templates',
      name: 'HeaderFooterTemplates',
      meta: {
        title: 'Templates Cabeçalho e Rodapé',
        permission: 'naja_app_header_footer_templates',
      },
      component: () => import('@/views/header_footer_templates'),
    },
    {
      path: '/header_footer_templates/new',
      name: 'HeaderFooterTemplateNew',
      meta: {
        title: 'Novo Template Cabeçalho e Rodapé',
        permission: 'naja_app_header_footer_templates_new',
      },
      component: () => import('@/views/header_footer_templates/edit'),
    },
    {
      path: '/header_footer_templates/:id',
      name: 'HeaderFooterTemplateShow',
      meta: {
        title: 'Visualizar Template Cabeçalho e Rodapé',
        permission: 'naja_app_header_footer_templates',
      },
      component: () => import('@/views/header_footer_templates/show'),
    },
    {
      path: '/header_footer_templates/:id/edit',
      name: 'HeaderFooterTemplateEdit',
      meta: {
        title: 'Editar Template Cabeçalho e Rodapé',
        permission: 'naja_app_header_footer_templates_edit',
      },
      component: () => import('@/views/header_footer_templates/edit'),
    },
    {
      path: '/email_templates',
      name: 'EmailTemplates',
      meta: {
        title: 'Templates de E-mail',
        permission: 'naja_app_email_templates',
      },
      component: () => import('@/views/email_templates'),
    },
    {
      path: '/email_templates/new',
      name: 'EmailTemplateNew',
      meta: {
        title: 'Novo Template de E-mail',
        permission: 'naja_app_email_templates_new',
      },
      component: () => import('@/views/email_templates/edit'),
    },
    {
      path: '/email_templates/:id',
      name: 'EmailTemplateShow',
      meta: {
        title: 'Visualizar Template de E-mail',
        permission: 'naja_app_email_templates',
      },
      component: () => import('@/views/email_templates/show'),
    },
    {
      path: '/email_templates/:id/edit',
      name: 'EmailTemplateEdit',
      meta: {
        title: 'Editar Template de E-mail',
        permission: 'naja_app_email_templates_edit',
      },
      component: () => import('@/views/email_templates/edit'),
    },
    {
      path: '/doctor_signatures',
      name: 'DoctorSignatures',
      meta: {
        title: 'Assinaturas dos médicos',
        permission: 'naja_app_doctor_signatures',
      },
      component: () => import('@/views/doctor_signatures'),
    },
    {
      path: '/doctor_signatures/new',
      name: 'DoctorSignatureNew',
      meta: {
        title: 'Nova Assinatura do Médico',
        permission: 'naja_app_doctor_signatures_new',
      },
      component: () => import('@/views/doctor_signatures/edit'),
    },
    {
      path: '/doctor_signatures/:id',
      name: 'DoctorSignatureShow',
      meta: {
        title: 'Visualizar Assinatura do Médico',
        permission: 'naja_app_doctor_signatures',
      },
      component: () => import('@/views/doctor_signatures/show'),
    },
    {
      path: '/doctor_signatures/:id/edit',
      name: 'DoctorSignatureEdit',
      meta: {
        title: 'Editar Assinatura do Médico',
        permission: 'naja_app_doctor_signatures_edit',
      },
      component: () => import('@/views/doctor_signatures/edit'),
    },
    {
      path: '/slugs',
      name: 'Slugs',
      meta: {
        title: 'Slugs',
        permission: 'naja_app_slugs',
      },
      component: () => import('@/views/slugs'),
    },
    {
      path: '/slugs/new',
      name: 'SlugNew',
      meta: {
        title: 'Novo Slug',
        permission: 'naja_app_slugs_new',
      },
      component: () => import('@/views/slugs/edit'),
    },
    {
      path: '/slugs/:id',
      name: 'SlugShow',
      meta: {
        title: 'Visualizar Slug',
        permission: 'naja_app_slugs',
      },
      component: () => import('@/views/slugs/show'),
    },
    {
      path: '/slugs/:id/edit',
      name: 'SlugEdit',
      meta: {
        title: 'Editar Slug',
        permission: 'naja_app_slugs_edit',
      },
      component: () => import('@/views/slugs/edit'),
    },
    {
      path: '/notification_settings',
      name: 'NotificationSettings',
      meta: {
        title: 'Configurações de Notificação',
        permission: 'naja_app_notifications_settings',
      },
      component: () => import('@/views/notification_settings'),
    },
    {
      path: '/notification_settings/new',
      name: 'NotificationSettingNew',
      meta: {
        title: 'Nova Configuração de Notificação',
        permission: 'naja_app_notifications_settings_new',
      },
      component: () => import('@/views/notification_settings/edit'),
    },
    {
      path: '/notification_settings/:id',
      name: 'NotificationSettingShow',
      meta: {
        title: 'Visualizar Configuração de Notificação',
        permission: 'naja_app_notifications_settings',
      },
      component: () => import('@/views/notification_settings/show'),
    },
    {
      path: '/notification_settings/:id/edit',
      name: 'NotificationSettingEdit',
      meta: {
        title: 'Editar Configuração de Notificação',
        permission: 'naja_app_notifications_settings_edit',
      },
      component: () => import('@/views/notification_settings/edit'),
    },
    {
      path: '/medical_report_settings',
      name: 'MedicalReportSettings',
      meta: {
        title: 'Configurações de filas de trabalho',
        permission: 'naja_app_medical_report_settings',
      },
      component: () => import('@/views/medical_report_settings'),
    },
    {
      path: '/medical_report_settings/new',
      name: 'MedicalReportSettingNew',
      meta: {
        title: 'Nova Configuração de Fila de Trabalho',
        permission: 'naja_app_medical_report_settings_new',
      },
      component: () => import('@/views/medical_report_settings/edit'),
    },
    {
      path: '/medical_report_settings/:id',
      name: 'MedicalReportSettingShow',
      meta: {
        title: 'Visualizar Configuração de Fila de Trabalho',
        permission: 'naja_app_medical_report_settings',
      },
      component: () => import('@/views/medical_report_settings/show'),
    },
    {
      path: '/medical_report_settings/:id/edit',
      name: 'MedicalReportSettingEdit',
      meta: {
        title: 'Editar Configuração de Fila de Trabalho',
        permission: 'naja_app_medical_report_settings_edit',
      },
      component: () => import('@/views/medical_report_settings/edit'),
    },
    {
      path: '*',
      component: () => import('@/views/page_not_found'),
      meta: { layout: 'basic', public: true },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.meta.public || store.getters.isLogged) {
    if (to.meta.permission) {
      const user = store.state.session.user
      if (
        !user.system_operations.includes(to.meta.permission) &&
        user.role !== 'admin'
      ) {
        alert('Acesso negado')
        return null
      }
    }
    next()
  } else {
    Api.toLogin()
    return null
  }
})

export default router
