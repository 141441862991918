import Vue from 'vue'
import App from './App.vue'
import NajaUi from 'naja-ui/dist/naja-ui.common'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import rules from './helpers/rules'

import 'naja-ui/dist/assets/css/theme.css'
import './assets/css/theme_override.css'
import './assets/css/main.css'
Vue.use(NajaUi)

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective)

import permissions from './plugins/permissions'
Vue.use(permissions)

Vue.config.productionTip = false
Vue.prototype.$rules = rules

Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0])
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  created() {
    if (window.performance.navigation.type == 1) {
      if (this.$route.path != '/auth') {
        store.dispatch('getUserInfo')
      }
    }
  },
}).$mount('#app')
