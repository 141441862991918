<template>
  <div class="app nj-app">
    <TermsModal ref="termsModal" />
    <v-snackbar ref="toast" v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.message }}
    </v-snackbar>
    <v-snackbar ref="toast" v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.message }}
    </v-snackbar>
    <nj-basic-layout v-if="layout == 'basic'">
      <router-view></router-view>
    </nj-basic-layout>
    <nj-main-layout
      v-else
      :organization="organization"
      :user="user"
      :menu-items="menuItems"
      :menu-dark="true"
      menu-color="primary"
      :page-title="$route.meta.title || $route.name"
      @logout="$store.dispatch('logout')"
      :whiteLogo="true"
    >
      <keep-alive :include="keepAliveRoutes">
        <router-view :key="$route.fullPath"></router-view>
      </keep-alive>
    </nj-main-layout>
  </div>
</template>

<script>
import TermsModal from '@/components/TermsModal.vue'
import { mapState, mapGetters } from 'vuex'

const MENU = [
  { title: 'Painel Principal', icon: 'mdi-home', to: '/' },
  {
    title: 'Usuários',
    icon: 'mdi-account-multiple',
    to: '/users',
    permissions: ['naja_app_users_show'],
  },
  {
    title: 'Agendamentos',
    icon: 'mdi-calendar-month-outline',
    to: '/schedules',
    permissions: ['naja_app_users_show'],
  },
  {
    title: 'Segurança',
    icon: 'mdi-security',
    to: null,
    permissions: ['naja_app_roles_show'],
    submenu: [
      {
        title: 'Perfis de Acesso',
        to: '/profiles',
        icon: 'mdi-shield-account-outline',
      },
    ],
  },
  {
    title: 'Parametrização',
    icon: 'mdi-tune',
    to: null,
    permissions: ['naja_app_roles_show'],
    submenu: [
      {
        title: 'Agendamento de Tarefas',
        to: '/task_schedulers',
        icon: 'mdi-clock-edit-outline',
      },
      {
        title: 'Templates Cabeçalho e Rodapé',
        to: '/header_footer_templates',
        icon: 'mdi-clock-edit-outline',
      },
      {
        title: 'Templates de E-mail',
        to: '/email_templates',
        icon: 'mdi-clock-edit-outline',
      },
      {
        title: 'Assinaturas dos Médicos',
        to: '/doctor_signatures',
        icon: 'mdi-clock-edit-outline',
      },
      {
        title: 'Slugs',
        to: '/slugs',
        icon: 'mdi-clock-edit-outline',
      },
      {
        title: 'Configurações de filas de trabalho',
        to: '/medical_report_settings',
        icon: 'mdi-clock-edit-outline',
      },
    ],
  },
  {
    title: 'Notificações',
    icon: 'mdi-message-badge',
    to: null,
    permissions: ['naja_app_roles_show'],
    submenu: [
      {
        title: 'Caixas de saída',
        to: '/outboxes',
        icon: 'mdi-archive-edit-outline',
      },
      {
        title: 'Mensagens de entrada',
        to: '/inbox_messages',
        icon: 'mdi-archive-edit-outline',
      },
      {
        title: 'Log de Notificações',
        to: '/notifications',
        icon: 'mdi-archive-edit-outline',
      },
      {
        title: 'Configurações de Notificação',
        to: '/notification_settings',
        icon: 'mdi-archive-edit-outline',
      },
    ],
  },
]

export default {
  name: 'App',
  components: { TermsModal },

  watch: {
    $route() {
      const requiresTerms =
        this.$store.state.session.organization.termos_portal.id != null
      if (requiresTerms) {
        if (this.$store.getters.isLogged) {
          const terms = this.$store.state.session.organization.termos_portal.id
          if (
            !this.$store.state.session.user.termos_uso_aceitos.includes(terms)
          ) {
            this.$refs.termsModal.open()
          }
        }
      }
    },
  },

  computed: {
    ...mapGetters(['systemOperations']),
    ...mapState({
      organization: state => state.session.organization,
      user: state => state.session.user,
    }),

    menuItems() {
      return MENU
      // return MENU.filter(
      //   menu =>
      //     menu.permissions == null ||
      //     menu.permissions.some(val => this.systemOperations.includes(val))
      // )
      // return []
    },

    layout() {
      return this.$route.meta.layout || 'default'
    },
  },

  data() {
    return {
      drawer: true,
      expanded: false,
      mobileDrawer: false,
      snackbar: {
        show: false,
        timeout: 5000,
        message: null,
      },
      keepAliveRoutes: ['Users'],
      // menuItems: [
      //   { title: 'Painel Principal', icon: 'mdi-home', to: '/' },
      //   {
      //     title: 'Usuários',
      //     icon: 'mdi-account-multiple',
      //     to: '/users',
      //     show: this.$hasPermission('naja_app_users_show'),
      //   },
      //   {
      //     title: 'Segurança',
      //     icon: 'mdi-security',
      //     to: null,
      //     submenu: [
      //       {
      //         title: 'Perfis de Acesso',
      //         to: '/profiles',
      //         icon: 'mdi-shield-account-outline',
      //       },
      //     ],
      //   },
      // ].filter(x => x.show == null || x.show),
    }
  },

  methods: {
    toast(message) {
      this.snackbar.message = message
      this.snackbar.show = true
    },
  },
}
</script>
<style scoped>
.calendar-options >>> .v-toolbar__content {
  padding: 0px !important;
}

.root-app-bar {
  background-color: var(--nj-primary-color) !important;
  color: white;
  position: fixed;
  top: 0;
  z-index: 100;
}

.root-app-bar >>> .v-toolbar__title {
  font-weight: 500;
}

/* main-drawer overrides */
.nj-app >>> .main-drawer .v-list .v-list-item--active,
.nj-app >>> .main-drawer .v-list .v-list-item--active .mdi:before,
.nj-app >>> .main-drawer .mdi:before,
.nj-app >>> .main-drawer .fixed .mdi:before {
  color: white !important;
}
</style>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
@media #{map-get($display-breakpoints, 'xs-only')} {
  .root-container {
    padding: 25px 17px !important;
    margin-top: 50px;
  }
}
</style>
